<template>
  <div v-if="article">
    <div class="backgroundOverride"></div>
    <h1 class="Title">{{ article.title }}</h1>
    <p class="text-muted">
      {{ formatDate(article.startdate) }} to
      {{ formatDate(article.enddate) }}
    </p>
    <div class="article overflow-auto wrapper">
      <p class="text-muted sentence_case">
        <em>{{ article.description }}</em>
      </p>
      <figure class="figure">
        <img
          class="figure-img img-fluid rounded float-left"
          :src="article.image"
          :alt="article.imageCaption"
        />
        <figcaption class="figure-caption sentence_case">
          {{ article.imageCaption }}
        </figcaption>
      </figure>
      <p class="fulltext sentence_case">{{ article.fulltext }}</p>
    </div>
  </div>
  <div v-else>
    <Loading :messageWhenNoItems="messageWhenNoItems"></Loading>
  </div>
</template>
<script>
import ProjectsAPI from '@/api/projects.js'
import Loading from '@/components/common/Loading.vue'

export default {
  name: 'Project',
  components: {
    Loading,
  },
  data() {
    return {
      messageWhenNoItems: 'Loading project information...',
      article: null,
      articleId: [],
    }
  },
  methods: {
    async getRoute() {
      this.articleId = this.$route.params.id
    },
    formatDate(date) {
      const options = {
        year: 'numeric',
        month: 'long',
      }
      return new Date(date).toLocaleDateString('en-GB', options)
    },
  },
  created() {
    this.getRoute()
    ProjectsAPI.getId(this.articleId)
      .then((response) => (this.article = response.data))
      .catch((error) => console.log(error))
  },
}
</script>
<style scoped>
.backgroundOverride {
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5) 15%,
    rgba(255, 255, 255, 1) 20%
  ) !important;
}
</style>
